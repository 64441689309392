import React from 'react'
import { Link } from 'gatsby'
import ReactMarkdown from 'react-markdown'
import { GatsbyImage } from 'gatsby-plugin-image'

const BlogCategories = ({ nodes }) => {
  return (
    <>
      {nodes.map(node => {
        return (
          <section className="blog-details-area\">
            <div className="container">
              <div className="row">
                <div className="col-12 pb-70">
                  <Link to={`/blog/${node.slug}`}>
                    <GatsbyImage
                      alt={node.title}
                      image={node.image.childImageSharp.gatsbyImageData}
                    />
                  </Link>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <Link to={`/blog/${node.slug}`}>
                    <h1>{node.title}</h1>
                  </Link>
                </div>
                <div className="col-12 pb-4">
                  <p class="text-start">{node.date}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="blog-details-desc">
                    <ReactMarkdown source={node.content} />
                  </div>
                </div>
                <div className="widget-area m-0 pb-100">
                  <div className="tagcloud">
                    {node.categories.map(items => {
                      return (
                        <Link to={`/category/${items.slug}`}>
                          {items.category}
                        </Link>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          </section>
        )
      })}
    </>
  )
}

export default BlogCategories
