import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/App/Layout'
import Navbar from '../components/Common/Navbar'
import PageBanner from '../components/Common/PageBanner'
import Footer from '../components/Common/Footer'
import BlogCategories from '../components/Common/BlogCategories'

const ComponentName = ({ data, pageContext }) => {
  const { nodes } = data.blog

  return (
    <Layout title={`${pageContext.category} — Brewedlogic Inc.`}>
      <Navbar />

      <PageBanner
        pageTitle={pageContext.category}
        homePageText="Blog"
        homePageUrl="/blog"
        activePageText={pageContext.category}
      />

      <BlogCategories nodes={nodes} />

      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query GetAllBlogWithCategory($slug: String) {
    blog: allStrapiBlog(
      sort: { fields: date, order: DESC }
      filter: { categories: { elemMatch: { slug: { eq: $slug } } } }
    ) {
      nodes {
        title
        slug
        content
        image {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        categories {
          category
          slug
        }
        date: date(formatString: "MMMM D, YYYY")
      }
    }
  }
`

export default ComponentName
